/* .social-icon {
	display: block;
	float: left;
	margin: 1px 1px 2px 0;
	text-align: center !important;
	font-size: 10px;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	cursor: pointer;
	font-style: normal;
    margin: auto;
}
 */
 .social-icon-blog {
	margin: 0 1px 1px 0;
	width: 40px;
	height: 40px;
	font-size: 1.25rem;
	line-height: 38px !important;
	color: #555;
	text-shadow: none;
	border: 1px solid #AAA;
	border-radius: 3px;
	overflow: hidden;
    float: right;
}