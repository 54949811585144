.grid-containerService {
    display: grid;
    grid-template-columns: auto auto ;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    font-size: 18px;
    text-align: left;
  }

  .box {
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
}

.svgs {
    width: 500px;
    height: auto;
    float: left;
    background-size: 100px 100px;
    margin: 1rem;
}

@media (max-width: 768px) {
  .svgs {
      width: auto;
      height: auto;
      float: left;
      background-size: 100px 100px;
      margin: 1rem;
  }
}