.color-orange{
	color: #ff6000;
}

.btn-orcamento{
	margin: 10px 5px;
	background-color: #ff6000;
	border: none;
	border-radius: 5px;
	padding: 6px 20px;
	color: white;
	font-weight: bold;
    text-transform: none;

}

a{
	text-decoration: none;
}

.btn-saiba-mais{

    border: none;
    border-radius: 0.25rem;
	background-color: #ff6000;
    color:#373737;
    font-weight: bold;
    padding: 0.6rem 0.6rem 0.6rem 0.6rem;
	font-size: 0.85rem;
	transition: 0.3ms;
}

.btn-saiba-mais:hover {
	background-color: #373737;
    color: white;
}

.service{
	transition: transform 0.5s; 
}

.service:hover{
	 transform: scale(1.3);
}