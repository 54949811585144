.logo{
	transition: transform 0.5s; 
}

.logo:hover{
	 transform: scale(1.2);
}

.logotech{
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-bottom: 2rem;
}

.logotech span{
	font-size: 0.8rem;
	padding-right: 0.4rem;
	font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif, 'Times New Roman', Times, serif;
}
