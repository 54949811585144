.mgtop{
    margin-top: 3rem;
}

.color-orange {
    color: #ff6000;
}

.titulo{
    font-weight: bold;
    margin-top: 4rem;
}

.bold{
    font-weight: bold;
}

.gridcontainer{
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    grid-template-columns: auto auto;
    margin-top: 5%;
}

.grid-item {
    text-align: left;
}

.superTitle {
    grid-column: 1 / -1;
 }

.descricao{
    font-size: 0.95rem;
}

.btn-orcamento{
    background-color: #ff6000;
    color: white;
    border: 0;
    border-radius: 0.25rem;
}

@media (max-width: 999px) {
    .mgtop{
        margin-top: 4rem;
    }

    .gridcontainer{
        grid-template-columns: auto;
    }
}