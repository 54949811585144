.position-absolute {
    bottom: 3rem;
    padding-left: 42%;
    padding-right: 5%;
}

.position-absolutebtnorc{
    position: absolute;
    top: 27%;
    padding-left: 26%;
}


.position-absoluteimg{
    position: absolute;
    top: 45%;
    padding-left: 10%;
    padding-right: 10%;
}

.orcamento {
    position:absolute; 
    top: 11rem;
    padding-left: 17%;
}

.orcamento button {
    margin-left: 12%;
}

.orcamento div{
    margin-right: 5%;
}

.arrowbtnpedreira{
    width: 65%;
    padding-right: 10px;
}

.btn-gray{
	margin: 15px 5px;
	background-color: #333333;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	color: white;
	font-weight: bold;
}

.btn-saiba-mais-orange{
	margin: 5px 5px;
	padding: 10px 20px;
}


.logohistoria{
    bottom: 13%;
    padding-left: 27%;
    max-width: 45%;
}

.titulohistoria{
    bottom: 16%;
    padding-left:50%;
    text-align: justify;
    width: 90%;
}

.titulohistoria p {
    color: #ff6000;
    font-size: 1.8rem;
    font-weight: bold;
}

.titulohistoria span {
    font-size: 1.2rem;
}

@media (max-width: 1360px) {
 

    .arrowbtnpedreira{
        width: 60%;
        padding-right: 15px;
    }

    .orcamento {
        top: 29%;
        padding-left: 21%;
    }

    .btn-saiba-mais-orange{
        margin: 0px 5px;
    }

    .logohistoria{
        bottom: 13%;
        padding-left: 27%;
        max-width: 45%;
    }

    .titulohistoria{
        bottom: 10%;
        padding-left:50%;
        text-align: justify;
        width: 90%;
    }
    
}

@media (max-width: 1190px) {
    .position-absolute {
        bottom: 2rem;
        padding-left: 42%;
    }

    .orcamento {
        top: 25%;
        padding-left: 21%;
    }

    .logohistoria{
        bottom: 13%;
        padding-left: 27%;
    }
}

@media (max-width: 1080px) {
    .orcamento {
        top: 25%;
        padding-left: 21%;
    }

    .logohistoria{
        bottom: 13%;
        padding-left: 27%;
    }
   
}

@media (max-width: 992px) {
    .position-absolute {
        bottom: 10%;
        padding-right: 7%;
    }

    .arrowbtnpedreira{
        width: 55%;
        padding-right: 15px;
    }

    .orcamento {
        top: 25%;
        padding-left: 21%;
    }

    .btn-saiba-mais-orange{
        margin: 0px 5px;
        font-size: 0.8rem;
    }

    .logohistoria{
        bottom: 6%;
        padding-left: 27%;
        max-width: 50%;
    }

    .titulohistoria{
        bottom: 4%;
        padding-left:50%;
        text-align: center;
        width: 100%;
    }

    .titulohistoria p {
        color: #ff6000;
        font-size: 1.2rem;
        font-weight: bold;
    }
    
    .titulohistoria span {
        font-size: 0.8rem;
    }
    .btnorc{
        top: 6rem;
        padding-left: 26%;
        font-size: 0.7rem;
    }
    
}

@media (max-width: 810px) {
    .orcamento {
        top: 25%;
        padding-left: 21%;
    }
    .position-absolute {
        bottom: 8%;
        padding-right: 7%;
    }


  
}

@media (max-width: 768px){
    
    .btn-gray{
        margin: 10px 5px;
        background-color: #333333;
        border: none;
        border-radius: 5px;
        padding: 8px 16px;
        color: white;
        font-weight: bold;
        font-size: 0.8rem;
    }
}
