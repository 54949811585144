ol{
    grid-gap: 20px;
}

.carousel-indicators li {
    background-color: #FF6000;
    border-radius: 50%;
    height: 0.6rem;
    opacity: 1.0;
    width: 0.6rem;
  }

.carousel-indicators li.active{
    background-color: white;
}

.carousel{
    position: relative;
}

.carousel-caption {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3vw;
    font-weight: bold;
}

.banTitle{
    margin: 0;
    font-size: 5vw;
    font-weight: bold;
    color: #FFC619;
}

  @media (max-width: 576px) {
    .banner{
        padding-top: 68px;
    }
    .carousel-indicators li{
        display: none;
    }
    span.carousel-control-prev-icon{
        display: none;
    }
    span.carousel-control-next-icon{
        display: none;
    }
  }