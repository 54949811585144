.header {
  background: white;
/*   border-bottom: solid 1px #ff6200ea;
  box-shadow: 2px 2px 2px rgba(87, 86, 86, 0.432); */
}


.nav-link{
  color: #F07D00 !important;
}
.nav-link:hover {
  color: #111111 !important;
}

@media (min-width: 900px) {
  .cNav{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 50px;
  }

  .nav-options {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 50px;
    list-style-type: none;
  }
}
